<mat-tab-group
  [color]="color"
  [backgroundColor]="backgroundColor"
  (selectedIndexChange)="updateOutput({ index: $event })"
>
  <mat-tab *ngFor="let tab of _tabs">
    <ng-template mat-tab-label>
      @if (isExpression(tab.label) || isString(tab.label)) {
        {{ tab.label | parse: context | async }}
      } @else {
        <ng-container
          [dynamicWidget]="tab.label"
          [context]="context"
        ></ng-container>
      }
    </ng-template>
    <ng-container
      [dynamicWidget]="tab.widget"
      [context]="context"
    ></ng-container>
  </mat-tab>
</mat-tab-group>
